/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "@fontsource/open-sans"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.prevUrl = prevLocation ? prevLocation.pathname : null
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("react-intersection-observer")
    console.log("IntersectionObserver polyfilled ;)")
  }
}
